import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Seo from "../../components/Seo/Seo"
import ArticleListing from "../../components/ArticleListing"
import "../index.sass"
import PageTransition from "../../components/PageTransition";

const OpinionPage = (props) => {

	const { data } = props
	const posts = data.allMarkdownRemark.edges

	return (

		<PageTransition {...props}>

			<Seo title="Opinion" keywords={[]} />

			<div className="homepage">

				<h1 id="main-title">Thoughts</h1>
				<p className="lead-message">Personal musings and anecdotes, designed to provoke thought.</p>

				<ArticleListing posts={posts} />

			</div>

		</PageTransition>

	)

}

OpinionPage.propTypes = {

	data: PropTypes.object.isRequired,

}

export default OpinionPage

export const pageQuery = graphql`

	query {
		allMarkdownRemark(

			filter: {
				fields: {
					category: {eq: "Thoughts"}
				}
				frontmatter: {
					draft: {ne: true}
				}
			}

			sort: {
				order: DESC, fields: [frontmatter___articlePublicationDate]
			}

		) {
		edges {
				...indexListing
			}
		}
	}

`